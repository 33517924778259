import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from 'pli/styles';

@customElement('page-layout')
class Layout extends LitElement {
    static styles = [
        styles.padding,
        styles.grid,
        css`
            :host {
                --sidebar-width: 7rem;
                --main-content-bottom-padding: var(--sidebar-width);
                --main-content-min-width: 72rem;
            }
            .sidebar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: var(--sidebar-width);
            }
            main {
                padding-left: var(--sidebar-width);
                min-height: 100vh;
                min-width: var(--main-content-min-width);
            }

            .main-content {
                padding: var(--size-1-5);
                padding-bottom: var(--main-content-bottom-padding);
            }
        `,
    ];
    protected render(): unknown {
        return html`<main>
            <div class="sidebar">
                <slot name="sidebar"></slot>
            </div>
            <div class="main-content">
                <slot></slot>
            </div>
        </main>`;
    }
}
